/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { Link } from 'gatsby';
import { BASE_URL } from 'config';

class ArohaAotearoa extends PureComponent {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://starling.crowdriff.com/js/crowdriff.js';
    script.id = 'cr-init__f88830eaa8c3183e';
    script.async = true;
    document.querySelector('.gallery').appendChild(script);
  }

  render() {
    const panelItems = [
      {
        title: '10-day South Island Snow Safari',
        content: `<div>Christchurch to Queenstown</div>
        <div>Now from: $2399 Save: $600</div>
        <p>Welcome to the best one-week guided ski and snowboard tour of the South Island! Experience the best runs and powder stashes across six different mountains over seven days, visiting Queenstown, Wanaka, and Mt Hutt. Great for beginners and expert skiers alike.</p>`,
        link: '/new-zealand-snow-tours/south-island-snow-safari/',
        style: '',
        image: {
          width: 652,
          height: 418,
          title: '10-day South Island Snow Safari',
          url: 'https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e01c/IMG_3600-medium_original.jpg?w=652&h=418',
        },
        class: 'd-none'
      },
      {
        title: '7-day South Island Snow Safari',
        content: `<div>Christchurch to Queenstown</div>
        <div>Now from $2,399pp | Save $599pp*</div>
        <p>Welcome to the best one-week guided ski and snowboard tour of the South Island! Experience the best runs and powder stashes across six different mountains over seven days, visiting Queenstown, Wanaka, and Mt Hutt. Great for beginners and expert skiers alike. <a href="/new-zealand-winter-tours/south-island-snow-safari">See more</a></p>`,
        link: '/new-zealand-winter-tours/south-island-snow-safari',
        style: '',
        image: {
          width: 652,
          height: 418,
          title: '7-day South Island Snow Safari',
          url: 'https://hakatours-staging.imgix.net/000/early_bird_snow_cardrona_chairlift.jpg?w=652&h=418',
        },
        class: ''
      },
    ]

    return (
      <Layout page="early-bird-haka-snow-tours">
        <Helmet
          title="Save on Snow with Early Bird Offer"
          meta={[
            {
              name: 'description', content: 'The 2023 snow season is approaching fast so it’s time to grab and secure your spot on our legendary snow tours. Our tours are designed to take you to multiple mountains on one trip, with a bunch of like-minded ski buddies to share this amazing experience with.',
            },
            { name: 'og:title', content: 'Save on Snow with Early Bird Offer' },
            {
              name: 'og:description', content: 'The 2023 snow season is approaching fast so it’s time to grab and secure your spot on our legendary snow tours. Our tours are designed to take you to multiple mountains on one trip, with a bunch of like-minded ski buddies to share this amazing experience with.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/early-bird-haka-snow-tours/` }]}
        />

        <Banner modifier={'early-bird-discount-banner overlay-20'} backgroundImage="https://hakatours-staging.imgix.net/000/early_Bird_snow_2024.jpg">
          {/* <h1 className="c-heading c-heading--h1">The Early Bird Catches the Powder</h1> */}
        </Banner>

        <section className="l-section" style={{ paddingBottom: '1rem' }}>
          <div className="l-container l-container--small u-text--center">
            <h1 className="c-heading c-heading--h2">Save on Snow with Early Bird Offer</h1>
            <h2 className="c-heading c-heading--h3 mt-4">Get ready for the perfect winter adventure with Haka Snow Tours!</h2>
            <p>The 2024 snow season is approaching fast so it’s time to grab and secure your spot on our legendary snow tours. Our tours are designed to take you to multiple mountains on one trip, with a bunch of like-minded ski buddies to share this amazing experience with.</p>
            <p>With Haka Tours, you don't have to worry about the logistics - we've got everything packaged for you, including your expert snow manager, ski lift passes, accommodation, bus transport, breakfasts, and more. So, all you have to do is focus on skiing and having a great time!</p>
            <h2 className="c-heading c-heading--h3 mt-4">Early Bird Discount</h2>
            <p>Book your Snow Tour before Friday 17 May 2024 and take advantage of our early bird discounts - save up to 20% off your tour.*</p>
            <p>This is a limited time offer, so make sure to book your tour soon!</p>
            <Link
              to={'/new-zealand-winter-tours/south-island-snow-safari/'}
              className="c-button c-button--primary c-button--wide mt-4"
            >
              BOOK NOW
            </Link>
          </div>
        </section>
        <section className="l-alternating-panels">
          <div className="l-container l-container--small u-text--center">
            {/* <h2 className="c-heading c-heading--h3 mt-4">Haka Snow Safari</h2> */}
            <br />
            <br />
          </div>
          <div className="l-container l-container--small">
            { panelItems.map(item => (
              <div className={`l-alt-panels__item ${item.class}`} key={item.title}>
                <div className="l-alt-panels__item-content">
                  <h4 className="l-alt-panels__item-title c-heading c-heading--h4"><Link to={item.link}>{item.title}</Link></h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
                <div className="l-alt-panels__item-image">
                  <Link to={item.link}>
                    <img src={item.image.url} alt={item.image.title} width={item.image.width} height={item.image.height} />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-4">
          <div className="l-container u-text--center">
            <div className="gallery"></div>
            <div className="mt-5">
              <a href="https://hakatours.com/about-us/reviews/">
                <img src="https://hakatours-staging.imgix.net/000/safari_guest_feedback.jpg" />
              </a>
            </div>
          </div>
        </section>
        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <h4 className="l-alt-panels__item-title c-heading c-heading--h4 mt-4">*Terms and Conditions</h4>
            <div className="fs-small">
              <p>
                <i>
                  Valid on new bookings only made from 15 April – 17 May 2024. Offer valid on Haka Snow Safari Tour, for travel 01July-30 September 2024. 
                  Early Bird Discount ranges from 10% - 20%. Departure date subject to availability at time of booking. 
                  Under no circumstances will the discounts be applied to existing bookings. 
                  If not otherwise specified against the individual promotion, full payment is required at least 60 days prior to travel. 
                  The discounts cannot be combined or used in conjunction with any other offers. Discounts are subject to availability; may be withdrawn at any point without notice. 
                  Does not apply to pre- or post-tour accommodation, insurance, international airfare not specified in the itinerary, upgrades, add-ons, equipment rental, transfers, theme packs, or other in-country or on-board services. 
                  For full general T&Cs visit <Link to='https://hakatours.com/booking-conditions/'>https://hakatours.com/booking-conditions/</Link>
                </i>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ArohaAotearoa;
